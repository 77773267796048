import {useSelector} from 'react-redux'
import React from "react";
import { useState,useEffect } from "react";
import Search from '../autoComplete'

const search_results = () =>{
    const {data} = useSelector(store => store.properties.listFilters)
    const [locations,setLocation] = useState([]) 
    const updateRoute = () => {
        return 0
    }

    useEffect(() => {
        for (let index = 0; index < data.objects.locations.length; index++) {
            setLocation(locations => [...locations , data.objects.locations[index].location_name])
        }
    },[]);


    return data ?(
        <div className="search-results d-none d-lg-block">
            <div className="container-fluid">
                <form action="">
                    <div className="row">
                        <div className="col-lg-3">
                            <label htmlFor="">
                                Tipo de propiedad
                            </label>
                            <div className="content-fields">
                                <i className="icon-home"></i>
                                <select name="type" id="type">
                                    {data.objects.property_types.map((item,index) =>(
                                        <option value={item.type}>{item.type}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <label htmlFor="">
                                Tipo de operación
                            </label>
                            <div className="content-fields second">
                                <select name="type" id="type">
                                    {data.objects.operation_types.map((item,index) =>(
                                        item.operation_type == 1 ?
                                        <option value={item.type}>Ventas</option>
                                        : item.operation_type == 2 ?
                                        <option value={item.type}>Alquileres</option>
                                        : ''
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-9">
                                    <label htmlFor="">Ubicación</label>
                                    <div className="content-fields">
                                        <i className="icon-location"></i>
                                        {/* <Search lang={locations} /> */}
                                        <Search lang={locations} updateRoute={updateRoute}/>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="" style={{opacity:0}}>-</label>
                                    <button className="btn">BUSCAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default search_results