import React , {useEffect} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'

import {getNoveltieAction,getNoveltiesAction,clearNoveltyAction} from '../redux/noveltiesDucks'
// -- Componentes -- //

import Main from '../components/blog/main'
import Details from '../components/blog/details'
import Contact from '../components/form'


// function ScrollToTopOnMount() {
//     useEffect(() => {
//         window.scrollTo(0, 0);      
//     }, []);
//       return null;
// }


function Blog(){

    const dispatch = useDispatch();
    const {id} = useParams();
    // ScrollToTopOnMount()


    useEffect(() => {
        dispatch(clearNoveltyAction())
        dispatch(getNoveltieAction(id));
        // dispatch(getNoveltiesAction())
      }, []);

    return(
        <div className="nosotros blog-ficha">
            <div className="layout">
                {/* <Main /> */}
                <div className="content">
                <Details />
                {/* <Contact /> */}
                </div>
            </div>
        </div>
    )
}

export default Blog
