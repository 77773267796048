import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";
import { getTextShort,getDateTime, getCompleteName } from '../../functions/functions_novelties'
   
const Blogs = () => {

    const dispatch = useDispatch()
    
    const settings = useSelector((store) => store.settings);
    const news = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    const loading_grid = useSelector((store) => store.novelties.loading_grid);

    useEffect(() => {
        dispatch(getFiltersNoveltiesAction())
    }, [])

    return tags || loading_grid ? (
        <section class="blog-list novelty-section">
        <div class="container">
            {console.log(news)}
            {news && !loading_grid ? 
                news.length > 0 ?
                    news.sort((a,b)=>b.position-a.position).map((item, index) => (
                        <article  class="novelty-card mt-4">
                            <div style={{backgroundImage: "url(" + item.image + ")"}} className="img-novelty"></div>
                            <div className="div-content d-flex flex-column p-4 text-start">
                                <div className="div_texto">
                                    <span className="date">{getDateTime(item.date_published)}</span>
                                    <h2 className="title mt-2">
                                        {item.title}
                                    </h2>
                                    <p className="description mt-5">
                                     {item.headline}
                                    </p>
                                </div>
                                <div className="div_button col-12 text-center mt-auto d-lg-flex justify-content-between">
                                    <div className="div_etiquetas flex-wrap">
                                        {item.tags.map((tag, index) => (
                                            <span className="pill me-2 mt-1 mt-lg-0" style={{marginRight:"5px"}} >{tag.name}</span>
                                        ))}
                                    </div>
                                    <Link to={settings.base_url + "novedades/" + item.id + "/" + item.slug} class=" btn btn-blue mt-3 mt-lg-0 ">Ver Más <span className="span-more"><span className="first-i">+</span></span></Link>
                                </div>
                                <span className="by-name">
                                    por {getCompleteName(item)}
                                </span>
                            </div>
                        </article>
                    )):
                    <h4 style={{fontSize:"1rem"}}>
                        No existen novedades relacionadas con el filtro de búsqueda seleccionado
                    </h4>
        :
            <>
                <div class="loader relative">
                    <div class="page-lines">
                        <div class="container">
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                        </div>
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                        </div>
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                        </div>
                    </div>
                    <div class="loader-brand">
                        <div class="sk-folding-cube">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                        </div>
                    </div>
                </div>
            </>
        }
        </div>
    </section>
    ):''
}

export default Blogs