// constantes
const dataInicial = {
    name:'Fanelli Trabalon',
    short_name:'fanelli-trabalon',
    // base_url:'/clientes/fanelli-trabalon/',
    base_url:'/',
    seo:{
        title:'Fanelli Trabalon - Inmobiliarias'
    }
    ,
    social:
    {
        facebook:'https://www.facebook.com/fanellitrabalon',
        instagram:'https://www.instagram.com/fanellitrabalon',
        twitter:'https://www.twitter.com/fanellitrabalon',
        linkedin:'',
        youtube:'',
    },
    contact:
    {
        phone:['03644 - 423344' , '03644 - 431692'],
        whatsapp:{link:'543644571181',visual:'3644 - 571181'},
        mail:'info@fanellitrabalon.com'
    },
    location:
    {
        address:'Moreno 841, Saenz Peña',
        region:'Chaco, Argentina',
        cp:'H3700HPQ'
    },
    keys:{
        tokko:'17d1069000b24e503adea14df24a655e6c1a414b',
        google:'',
        captcha:'6LdbrrAbAAAAAGfnMeQRVgknA9wis0AYma3ZmbMR',
        google_tgm:'',
        google_map:'AIzaSyA5vYzWWG8cFq13JW7-wWg0JP1I5RDPhuE',
        ga_analytics_mediacore:'G-3D1GKKPD4V'
    },
    accessBtn: {
        title: "ACCESO CLIENTES",
        link: "https://gexion.com.ar/#/home",
        textLink: "INGRESAR"
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}
