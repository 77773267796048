import React from 'react'

function AccessBtn({title,link,textLink,newWind = false}) {
    return (
        <div className="btn-access-wrapper">
            <h5 className="title-btn">{title || "ACCESO CLIENTES"}</h5>
            <a href={link || "https://gexion.com.ar/#/home"} 
               target={newWind ? "_blank" : "_self"} 
               className="btn btn-access d-flex align-items-center justify-content-center">
                <i className="icon-key"></i>
                {textLink || "INGRESAR"}
            </a>
        </div>
    )
}

export default AccessBtn