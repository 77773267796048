import React from 'react'
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';
import homeTasaciones from "../../assets/images/home-tasaciones.webp";

function Tasaciones (){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    return  (
        <div className="tasaciones">
            <div className="container-fluid">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-7 pe-xxl-0">
                        <span>Conocé el valor de tu propiedad</span>
                        <h2>
                            Ahora podés tasar <br className="d-none d-lg-block" />
                            tu propiedad <br className="d-none d-lg-block" />
                            de forma rápida y segura. <br className="d-none d-lg-block" />
                            Completá los datos <br className="d-none d-lg-block" />
                            así podemos brindarte <br className="d-none d-lg-block" />
                            un valor aproximado. <br className="d-none d-lg-block" />
                            ¿Calculamos? 
                        </h2>
                    </div>
                    <div className="col-lg-4 d-flex flex-column align-items-lg-start">
                        <img src={homeTasaciones} alt="" />
                        <Link to={settings.base_url + "tasaciones"}  onClick={() => dispatch(changeLocationAction("tasaciones"))} className="btn mt-lg-auto">TASÁ TU PROPIEDAD AHORA</Link>
                    </div>
                </div>
            </div>
            <div id="sub" alt=""> </div>
        </div>
    ) 
}

export default Tasaciones
