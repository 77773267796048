import React, { useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import { changeLocationAction } from '../../redux/themeDucks';
import { getPropertiesFeaturedAction } from '../../redux/propertiesDucks';
import { shortDirection } from '../../functions/functions_property'

function Lotes (){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const {data} = useSelector(store => store.properties.properties_featured)

    useEffect(() => {
        dispatch(getPropertiesFeaturedAction())
    }, [])

    function shortLocation (full_location){
        var split = full_location.split("|")
        return split[3] + '| ' + split[1] 
    }

    return data ? (
        <div className={"lotes " + (data.objects.filter(p => p.type.id === 1).length > 0 ? '' : 'd-none')}>
            <div className="container-fluid">
                <div className="grid-system">
                    <div className="grid_item alt-1">
                        <h2>
                            Conocé nuestros<br /> lotes 
                            <span> destacados </span>
                        </h2>
                        <p>Estás a un click de encontrar tu próxima inversión inmobiliaria.</p>
                    </div>
                    {data.objects.filter(p => p.type.id === 1).map((lote,index) => {
                        if(index < 4) {
                            return (<div className={'grid_item alt-'+ (index+2)} key={index}>
                                <img src={(lote.photos).find(element => element.is_front_cover === true).original} alt={shortLocation(lote.location.full_location).trim()} />
                                <div className="hover d-flex justify-content-between align-items-end">
                                    <div>
                                        <h5>{lote.address}</h5>
                                        <p>{shortLocation(lote.location.full_location)}</p>
                                        <p className="surface">{lote.surface} {lote.surface_measurement}</p>
                                    </div>
                                    <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(lote.operations[0].operation_type , lote.type.name, lote.location.name , lote.id , lote.fake_address)}>
                                        <i className="icon-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>)}
                    })}
                </div>
                <div className="row justify-content-center">
                    <Link to={settings.base_url + "ventas/terreno"} onClick={() => dispatch(changeLocationAction("ventas"))} className="btn btn-effect transparent d-inline-block">VER MÁS</Link>
                </div>
            </div>
        </div>
    ) 
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Lotes
