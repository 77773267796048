import React from "react"

// Images
import team1 from "../assets/images/team/Rami.webp"
import team2 from "../assets/images/team/Delfina.webp"
import team3 from "../assets/images/team/Paula.webp"
import team4 from "../assets/images/team/Eduardo.webp"
import team5 from "../assets/images/team/Alexis.webp"

function TeamMembers() {
    // Team Data
    const teamData = [
        {
            img: team1,
            title: "VENTAS Y TASACIONES",
            name: "Ramiro Trabalón Fanelli",
            mail: "ramiro@fanellitrabalon.com"
        },
        {
            img: team2,
            title: "ADMINISTRACIÓN",
            name: "María Delfina Trabalón Fanelli",
            mail: "delfina@fanellitrabalon.com"
        },
        {
            img: team3,
            title: "COBROS",
            name: "Paula Trabalón Fanelli",
            mail: "paula@fanellitrabalon.com"
        },
        {
            img: team4,
            title: "ASESORAMIENTO EN VENTAS",
            name: "Eduardo Trabalón",
            mail: ""
        },
        {
            img: team5,
            title: "ALQUILERES",
            name: "Alexis",
            mail: "alexis@fanellitrabalon.com"
        },
    ];

    return (
        <div className="d-flex flex-column align-items-lg-start align-items-center team-container my-4 my-lg-0">
        {teamData.map((t,i) => (
            <div class="d-flex align-items-center team-member mb-4" key={i}>
                <img src={t.img} alt={t.name} class="rounded-circle"/>
                <div class="content ms-4">
                    <h5>{t.title}</h5>
                    <p>{t.name} 
                       {(t.mail.length > 0) 
                            ? <><br/><a href={`mailto:+${t.mail}`}>{t.mail}</a></> 
                            : null }</p>
                </div>
            </div>))}
        </div>
    )
}

export default TeamMembers