import React, {useState, useEffect, useRef} from 'react'
import Search from '../autoComplete'
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import { clearPropertiesAction } from "../../redux/propertiesDucks";
import { changeLocationAction } from "../../redux/themeDucks";

import bannerImage from'../../assets/images/main-fanelli.webp'
import { getActionByPage, getActionByType } from '../../functions/functions_actions';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import OwlCarousel from 'react-owl-carousel3'
import { informCustomEvent } from '../../helpers/helper.analytics';
import AccessBtn from "../accessbtn"


function Main ({developments}){


    const history = useHistory()
    const dispatch = useDispatch();
    const refOperation = useRef()
    const refType = useRef()

    const settings = useSelector(store => store.settings)
    const {data} = useSelector(store => store.properties.listFilters)
    const dataDevelopments = useSelector(store => store.developments.developments.data)

    const [locations,setLocation] = useState([]) 

    const [router,setRouter] = useState({location:'',type:'departamento',operation:'ventas'})

    useEffect(() => {
        if(data){
            setLocation([])
            for (let index = 0; index < data.objects.locations.length; index++) {
                setLocation(locations => [...locations , data.objects.locations[index].location_name])
            }
        }
    },[data]);

    const updateRoute = (value) =>{
        setRouter((router) => ({
            ...router,
            [value.current.name]:value.current.value
        }))
    }

    const goRoute = event => {
        event.preventDefault()
        dispatch(changeLocationAction(router.operation.toLowerCase()))
        dispatch(clearPropertiesAction())
        history.push(settings.base_url+ router.operation.toLowerCase() + "/" + router.type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "/" + router.location.toLowerCase().replace(/ /g,"-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }

    // banner top

    const {actions} = useSelector(store => store.actions)


    const slider = useRef()


    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

    // useEffect(() => {
    //     if(api_key){
    //         dispatch(getDevelopmentsAction()) // Get all developments
    //         dispatch(getTotalListFilters()) // Get all developments
    //     }
    // },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }

    const getSize = () => (screen.width < 992) ? false : true
    
    const goTo = () => {
        if (video_action[0].url_destiny) {
            // window.open(video_action[0].url_destiny, '_blank', 'noopener,noreferrer')
            informCustomEvent("PUSH_" + video_action[0].small_section + "_" + video_action[0].small_type + "_" + video_action[0].name)
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
        }
    }
    return true ?(
        <div className="main home">
            <div className="transparencia d-block g-lg-none"></div>
            <div className={"container-fluid " + (image_action.length === 0 || image_action[0]?.url_destiny === '' ? '' : 'position-relative') }>
                <div className="row" id="content-main">
                    <div className="col-lg-4 d-flex align-items-start justify-content-center flex-column order-2 order-lg-1 first-col">
                        <h1>
                            Excelencia en 
                            servicios 
                            inmobiliarios.
                        </h1>
                        <p>
                            Más de 40 años de experiencia y seriedad Inmobiliaria
                        </p>
                        <div className="col-12">
                            <form onSubmit={goRoute} className="row no-gutters">
                                    <div className="col-6 pe-lg-2">
                                        <div className="content-fields">
                                            <i className="icon-home"></i>
                                            <select ref={refType} onChange={() => updateRoute(refType)} name="type" id="type">

                                                {data ? data.objects.property_types.map((item,index) =>(
                                                    <option value={item.type}>{item.type}</option>
                                                )) : <option>Cargando..</option>}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-lg-2">
                                        <div className="content-fields second">
                                            <select ref={refOperation} onChange={() => updateRoute(refOperation)} name="operation" id="operation">
                                                {data ? 
                                                    data.objects.operation_types.map((item,index) =>(
                                                    item.operation_type == 1 ?
                                                    <option value={item.type}>Ventas</option>
                                                    : item.operation_type == 2 ?
                                                    <option value={item.type}>Alquileres</option>
                                                    : ''
                                                ))  : <option>Cargando..</option> }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="content-fields mt-lg-3 mt-2">
                                            <i className="icon-location"></i>
                                            <Search lang={locations} updateRoute={updateRoute}/>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center position-relative">
                                        <button className="btn mt-lg-3 mt-2" type="submit">BUSCAR</button>
                                            <div className="btn-container position-absolute d-none d-lg-block">
                                                <AccessBtn
                                                    title={settings.accessBtn.title}
                                                    link={settings.accessBtn.link}
                                                    textLink={settings.accessBtn.textLink}
                                                    newWind={true}/>
                                            </div>
                                    </div>
                            </form>
                        </div>
                    </div>  
                    <div className='col-lg-8 d-lg-block order-1 order-lg-2 second-col postition-relative'>

                        {
                            slider_action?.length > 0 || video_action?.length > 0 
                            ?   <>
                                    {
                                        slider_action?.length > 0
                                        &&  <>
                                                { getSize() ?         
                                                        <div id="section-galery-home">
                                                            <div id="dev-secuencial">
                                                                <div className="slider" id="dev-gallery">
                                                                    <OwlCarousel
                                                                        items={1}
                                                                        ref={slider}
                                                                        margin={0}
                                                                        nav={false}
                                                                        dots={true}
                                                                        loop={true}
                                                                        rewind={true}
                                                                        autoplay={true}
                                                                        autoplayTimeout={parseInt(slider_action[0]?.transition_time)*1000}
                                                                        id="prop-gallery">
                                                                            { slider_action[0].files.map((file, index)=>(
                                                                                <div className="item banner-main banner-ficha emprendimiento" key={index}>
                                                                                    <img src={file.file} className="object-cover object-center cursor-pointer" alt="#"/>
                                                                                    <div className="info-banner position-absolute">
                                                                                        <div className="info row align-items-center text-center justify-content-center">
                                                                                            <div className="col-8 col-lg-12">
                                                                                                <a onClick={() => informCustomEvent("PUSH_" + slider_action[0].small_section + "_" + slider_action[0].small_type + "_" + slider_action[0].name)} target={'_blank'} href={file?.url_destiny + slider_action[0].text_utm} className={"link-gallery " + (!file.url_destiny && 'd-none')}></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )) }
                                                                    </OwlCarousel>
                                                                    <div className={"arrows-owl-isq zindex-1"}>
                                                                        <div className="arrow"  onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                                                                    </div>
                                                                    <div className={"arrows-owl-der zindex-1"}>
                                                                        <div className="arrow" onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <Searcher /> */}
                                                        </div>
                                                        :
                                                        <div id="home-main" onClick={() => image_action[0]?.url_destiny ? window.open(image_action[0].url_destiny + image_action[0].text_utm, '_blank', 'noopener,noreferrer') : ''} 
                                                            class={"banner-main " + (image_action.length === 0  ? 'cursor-default img-default' : '') + (image_action.length === 1  ? 'img-width-default' : '') } 
                                                            style={{backgroundImage: (image_action.length > 0 ? 'url(' + image_action[0]?.file + ')' : `url(${bannerImage})`)}} >
                                                        </div>
                                                    }
                                            </>
                                    }
                                    {
                                        video_action.length > 0
                                        &&  <>
                                                <div className={"banner-main " + (video_action[0].url_destiny === '' ? 'cursor-default ' : 'cursor-pointer ') + (video_action.length === 1  ? 'video-width-default' : '')} onClick={() => goTo() }>
                                                    { getSize() ?
                                                        <video loop autoPlay muted >
                                                            <source src={video_action[0].file} type="video/mp4" />
                                                        </video> 
                                                        :
                                                        <div id="home-main" onClick={() => image_action[0]?.url_destiny ? window.open(image_action[0].url_destiny + image_action[0].text_utm, '_blank', 'noopener,noreferrer') : ''} 
                                                            class={"banner-main " + (image_action.length === 0  ? 'cursor-default img-default' : '') + (image_action.length === 1  ? 'img-width-default' : '') } 
                                                            style={{backgroundImage: (image_action.length > 0 ? 'url(' + image_action[0]?.file + ')' : `url(${bannerImage})`)}} >
                                                        </div>
                                                    }
                                                </div>
                                        </>
                                    }
                                </>
                            :  
                            <>
                                {
                                    developments?.data?.objects?.length && !image_action && getLastFeatured(developments.data.objects) ? (
                                    <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + (developments.data ? getCover(getLastFeatured(developments.data.objects).photos)?.original : realEstate?.main_image) + ")"}} >
                                        <div class="item d-flex justify-content-center text-center align-items-center">
                                            <div class="opacity"></div>
                                            <div class="container" style={{zIndex:"1"}}>
                                                <div class="row align-items-center text-center justify-content-center">
                                                    <div class="col-12 pb-5">
                                                        <h1>{developments.data && getLastFeatured(developments.data.objects).name}</h1>
                                                        <p>
                                                            {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[0]}
                                                            <br />
                                                            {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[1]}
                                                        </p>
                                                        <a href={developments.data && makeLink(getLastFeatured(developments.data.objects))} target="_blank" class="btn btn-blue mb-5">DESCUBRILO</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Searcher /> */}
                                    </div>
                                    ):
                                    <div id="home-main" onClick={() => image_action[0]?.url_destiny ? window.open(image_action[0].url_destiny + image_action[0].text_utm, '_blank', 'noopener,noreferrer') + informCustomEvent("PUSH_" + image_action[0].small_section + "_" + image_action[0].small_type + "_" + image_action[0].name) : ''} 
                                        class={"banner-main " + (image_action.length === 0  ? 'cursor-default img-default' : '') + (image_action.length === 1  ? 'img-width-default' : '') } 
                                        style={{backgroundImage: (image_action.length > 0 ? 'url(' + image_action[0]?.file + ')' : `url(${bannerImage})`)}} >
                                        {image_action.length === 0 && <div class="height-100 item d-flex justify-content-center text-center align-items-center">
                                            <div class="opacity"></div>
                                            <div class="container" style={{zIndex:"1"}}>
                                                <div class="row align-items-center text-center justify-content-center">
                                                    <div class="col-12 pb-5">
                                                        <h1>
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {/* <Searcher /> */}
                                    </div>
                                }
                            </>
                        }
                        <div className="btn-container position-absolute d-lg-none">
                            <AccessBtn
                                title={settings.accessBtn.title}
                                link={settings.accessBtn.link}
                                textLink={settings.accessBtn.textLink}
                                newWind={true}/>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    ) 
    :
    (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main
