import { useSelector } from 'react-redux'
import BreadCrumbs from '../breadcrumbs'

const   Main = () =>{
    const settings = useSelector(store => store.settings)
    return (
        <section className="main">
            <div className="container-fluid full-fluid">
                <div className="row">
                    <div className="col-lg-4 h-100 d-flex justify-content-center flex-column">

                        <h2>Nosotros</h2>
                        <h5>
                            EXPERIENCIA Y SERIEDAD<br />
                        </h5>
                        <h5>
                            En 1983 Inmobiliaria Fanelli Trabalón nació y creció <br class="d-none d-lg-block" /> para brindarle soluciones con agilidad y creatividad comercial.
                        </h5>

                    </div>
                    <div className="col-lg-8 ps-0 h-100 h-sm-auto">
                        <img src="./assets/images/nosotros-1.jpg" alt="" />
                        <div className="row content-main ms-0 d-block">
                             <div className="col-12 mb-lg-3">
                                <BreadCrumbs props={[
                                    {name:'Home',route:settings.base_url +"",location:''},
                                    {name:'Nosotros',route:'',location:'',active:true}
                                ]}/>
                             </div>
                            <div className="d-lg-flex">
                                <div className="col-lg-2">
                                    {/* <span>
                                        Sobre nosotros <br />
                                        Desde 1983
                                    </span> */}
                                </div>
                                <div className="col-lg-10">
                                    <p>
                                    En una Argentina en crecimiento, una familia de emprendedores le dió su apellido al proyecto que tomaría el liderazgo en el rubro inmobiliario. Con nombre propio, que en adelante se convertiría en sinónimo de bienes raíces, sus fundadores soñaron con que su compañía fuera también un ejemplo de excelencia y tradición.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Durante poco más de tres décadas, esta visión se fue construyendo día a día con esfuerzo y dedicación a través de un conocimiento pormenorizado del mercado inmobiliario.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Ese sueño que tuvieron sus fundadores hoy es calidad con una marca que implica seriedad y experiencia en operaciones inmobiliarias.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Main