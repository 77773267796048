import React , {useEffect} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentAction } from '../redux/developmentsDucks';
import { getUnitsDevelopmentAction } from '../redux/developmentsDucks';

import Main from '../components/emprendimiento/main'
import Data from '../components/emprendimiento/data'
import Lotes from '../components/emprendimiento/lotes'
import Contact from '../components/emprendimiento/contact'

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Emprendimiento(){
    const dispatch = useDispatch();
    const {id} = useParams(); 
    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        const getDev = () => {
            window.scrollTo(0, 0);
            document.title = settings.seo.title
            dispatch(getDevelopmentAction(id))
            dispatch(getUnitsDevelopmentAction(id))
        }
        getDev()
    }, [])
        
    ScrollToTopOnMount()

    return(
        <div className="Emprendimiento">
            <Main />
            <Data />
            <Lotes />
            <Contact />
        </div>
    )
}

export default Emprendimiento
