import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getActionByPage, getActionByType } from "../../functions/functions_actions";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";
import BreadCrumbs from "../breadcrumbs";



const Main = () => {

    const dispatch = useDispatch();

    const settings = useSelector((store) => store.settings);
    const [listActives,setListActive] = useState([])
    const {data} = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    const actions = useSelector(store => store.actions.actions)


    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

  // Update filters in redux
  useEffect(() => {
    // if(activeTags.length > 0){
      dispatch(updateFiltersNovelties(listActives));
    // }
  }, [listActives])

  useEffect(() => {
    dispatch(clearNoveltyAction());
  } ,[])

    return tags ? (
        <main class="main main-inner main-blog bg-blog bg-zoom-in-out" data-stellar-background-ratio="0.6">
            <div className="container-fluid">
                <div className='d-flex flex-column justify-content-center' >
                        <BreadCrumbs props={[
                        {name:'Home',route:settings.base_url,location:''},
                        {name:'Novedades',route:'',location:'',active:true}
                    ]}/>
                </div>
                <div className="header_novelties">
                    <h2 className="title">Novedades</h2>
                    <h3 className="subtitle">Enterate de las últimas tendencias en el sector de real estate</h3>
                </div>
            </div>
            <div class="container-fluid fluid-blog">
                <div className="row buscador">
                    <div className="selected col-10">
                        <div className="flex-wrap">
                            <i className="icon-price-tag d-none d-lg-flex"></i>
                            {listActives.map((element, index) =>(
                                <div className="pill active wow fadeInRight"  style={{marginRight:"10px"}}>
                                    {tags.find(item => item.id === element).name}
                                    <button onClick={() => toggleTag(element)}><i className="icon-delete"></i></button>
                                </div>
                            ))}
                        </div>
                        <span className="d-none d-lg-flex" onClick={() => setListActive([])}>Limpiar</span>
                    </div>
                    <button onClick={() => dispatch(getFiltersNoveltiesAction(listActives))} className="btn-search col-2 d-none d-lg-flex"> Buscar <i className="icon-search"></i></button>
                    <button onClick={() => dispatch(getFiltersNoveltiesAction(listActives))} className="btn-search col-2 d-flex d-lg-none"><i className="icon-search"></i></button>
                </div>

                <div className="row mt-4 flex-wrap">
                    {tags.map((element,index) => (
                        listActives.find(e => e === element.id) ? '' :
                        <div className="col-xs-4 tag-off pl-lg-0 ">
                            <div key={index} onClick={() => toggleTag(element.id)} className="pill wow fadeInRight">{element.name}</div>
                        </div>
                    ))}
                </div>
            </div>

        {/* <!-- Lines --> */}

        <div class="page-lines">
            <div class="container-fluid">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </main>
    ):''
}

export default Main