import {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from '../breadcrumbs'
import { updateFields,formTasaciones_Tokko } from '../../redux/contactDucks'


const Main = () => {
    const dispatch = useDispatch()

    const type = useRef()
    const operation = useRef()

    const settings = useSelector(store => store.settings)
    const {data} = useSelector(store => store.properties.listFilters)
    const [step,setStep] = useState(0);
    const [disabled,setDisabled] = useState(true);
    const [datos, setDatos] = useState({
      nombre:'',
      email:'',
      ubicacion:'',
      sup_cub:'',
      sup_tot:'',
      rooms:'',
      garage:'',
      age:'',
      amenities:[]
    })
    const handleInputChange = (event) => {
        if(event.target.name == 'amenities'){
            if(datos.amenities.find(element => element === event.target.value)){
                const newAmenities = datos.amenities.filter(element => element !== event.target.value)
                setDatos({
                    ...datos,
                    [event.target.name] : newAmenities, 
                })
                return null
            }
        setDatos({
            ...datos,
            amenities : [...datos.amenities,event.target.value], 
        })
            return null
        }
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
        
    }
    const sendForm = (event) => {
        event.preventDefault()
        dispatch(formTasaciones_Tokko(type.current.value,operation.current.value))
        setStep(2)
        window.scrollTo(0, 0)
        document.getElementById("formContact").reset()
    }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])

    useEffect(() => {
        const getDevs = () => {
            if(step == 2){
                $('.results-nav').removeClass("results-nav").addClass("contact-nav");
            }
        }
        getDevs();
    })

    
    return (
        <section className={'main ' + (step == 2 ? 'step-2' : '')}>
            <div className={'container-fluid '  + (step !== 1 ? 'full-fluid ' : '')}>
                <div className={'row primary-row position-relative align-items-center ' + (step == 2 ? 'bg-transparent' : '')}>
                    <div className={'left-column d-flex align-items-start flex-column d-lg-block col-lg-7 ' + (step == 2 ? 'pt-0' : '')}>
                        {step == 2 ?
                            <div className={'d-flex left-column justify-content-start animate__animated animate__fadeInRight animate_faster flex-column justify-content-center ' + (step == 2 ? 'pt-0' : '')}>
                                <div className="tick d-flex justify-content-center align-items-center">
                                    <i className="icon-tick"></i>
                                </div>
                                <h2 className="text-thanks">
                                    Tu mensaje fue <br />
                                    enviado con éxito! <br />
                                    Gracias por contactarte <br />
                                    con nosotros <br />
                                </h2>
                            </div>
                        :
                            step == 0 ?
                            <div className="d-flex flex-column justify-content-center">
                                <BreadCrumbs props={[
                                    {name:'Home',route:settings.base_url,location:''},
                                    {name:'Tasaciones',route:'',location:'',active:true}
                                ]}/>
                                <span>Tasaciones</span>
                                <h2>
                                    Necesitas saber <br />
                                    cúal es el valor <br />
                                    de tu propiedad?
                                </h2>
                                <h2>Nosotros te ayudamos</h2>
                            </div>
                            :''
                        }
                        {step == 0 ?
                            <a  onClick={() => setStep(1) + window.scrollTo(0, 0)} className="btn btn-effect btn-movil-white transparent d-inline-block d-block">COMPLETAR FORMULARIO</a>
                        :''}
                    </div>
                    {step == 0 ?
                        //STEP 1
                        <div className="col-lg-5 img">
                                <img src="./assets/images/tasaciones-1.jpg" alt="" />
                        </div>
                    :
                        step == 1 ? //STEP 2
                        <div className="col-lg-12 left-column step-2-column animate__animated animate__fadeInRight animate_faster formulario d-flex flex-column justify-content-center">
                            <span>Tasaciones</span>
                            <h2>¡Completá el formulario!</h2>
                            <form onSubmit={sendForm} id="formContact" class="row mt-lg-5 mt-4 content-form ajax-form">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-6  camp mb-4">
                                            <input type="text" onChange={handleInputChange} required name="nombre" placeholder="Nombre y Apellido*" />
                                        </div>
                                        <div class="col-lg-6 camp">
                                            <input type="text" onChange={handleInputChange} required name="email" placeholder="E-Mail*" />
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="border-bottom-name"></div>
                                        </div>

                                        <div class="col-lg-6 pr-lg-3 pr-lg-2 mb-4 mb-lg-4">
                                            <input type="text" onChange={handleInputChange} required placeholder="Ubicación*" name="ubicacion" id="ubicacion" />
                                        </div>
                                        <div class="col-lg-6 pr-lg-3 pl-lg-2 mb-4 mb-lg-4 position-relative">
                                            <select onChange={handleInputChange} required ref={operation} name="operation">
                                                <option value="none">Operación*</option>
                                                <option value="Venta">Venta</option>
                                                <option value="Alquiler">Alquiler</option>
                                                <option value="Alquiler Temporario">Alquiler Temporario</option>
                                            </select>
                                            <i></i>
                                        </div>
                                        <div class="col-lg-6 pr-lg-3 mb-4 mb-lg-4 position-relative">
                                            <select ref={type} name="type">
                                                <option value="none">Tipo de propiedad *</option>
                                                {data.objects.property_types
                                                        .sort((a, b) => b.count - a.count)
                                                        .map((item, index) => (
                                                            <option value={item.type}>{item.type}</option>

                                                        ))}
                                            </select>
                                            <i></i>
                                        </div>

                                        <div class="col-12 pr-3 camp">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                <label> Ambientes</label>
                                                    <div class="ticks d-flex justify-content-between align-items-center">
                                                        <span class="option">
                                                            <input type="radio" id="amb-1" onChange={handleInputChange} name="rooms" value="1" />
                                                            <label for="amb-1" class="mb-0"><span class="">1</span></label>
                                                        </span>  
                                                        <span class="option">
                                                            <input type="radio" onChange={handleInputChange} id="amb-2" name="rooms" value="2" />
                                                            <label for="amb-2" class="mb-0"><span class="">2</span></label>
                                                        </span>  
                                                        <span class="option">
                                                            <input type="radio" onChange={handleInputChange} id="amb-3" name="rooms" value="3" />
                                                            <label for="amb-3" class="mb-0"><span class="">3</span></label>
                                                        </span>  
                                                        <span class="option">
                                                            <input type="radio" onChange={handleInputChange} id="amb-4" name="rooms" value="4" />
                                                            <label for="amb-4" class="mb-0"><span class="">4</span></label>
                                                        </span>  
                                                        <span class="option">
                                                            <input type="radio" onChange={handleInputChange} id="amb-5" name="rooms" value="5" />
                                                            <label for="amb-5" class="mb-0"><span class="">5</span></label>
                                                        </span>  
                                                        <span class="option">
                                                            <input type="radio" onChange={handleInputChange} id="amb-6" name="rooms" value="6" />
                                                            <label for="amb-6" class="mb-0"><span class="">+</span></label>
                                                        </span> 
                                                    </div>	
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 pr-3 mt-lg-5 py-3 py-lg-0 camp">
                                            <input type="text" name="sup_cub" onChange={handleInputChange} placeholder="Superficie Cubierta (m2)" />
                                        </div>
                                        <div class="col-lg-6 pl-3 mt-lg-5 py-3 py-lg-0 camp">
                                            <input type="text" name="sup_tot" onChange={handleInputChange} placeholder="Superficie Total (m2)"  />
                                        </div>
                                        

                                        <div class="col-lg-6 camp">
                                        <label>Antigüedad</label>
                                            <div class="ticks row d-flex justify-content-between align-items-center">
                                                <div class="col-6">
                                                    <span class="option d-flex align-items-center option-check">
                                                        <input type="radio" id="age-1" name="age" value="A estrenar" onChange={handleInputChange}/>
                                                        <label for="age-1" class="mb-0 mr-0"><span class="square"></span>A estrenar</label>
                                                    </span> 
                                                </div>
                                                <div class="col-6">
                                                    <span class="option option-check">
                                                        <input type="radio" id="age-2" name="age" onChange={handleInputChange} value="Hasta 10 años" />
                                                        <label for="age-2" class="mb-0 mr-0"><span class="square"></span>Hasta 10 años</label>
                                                    </span> 
                                                </div>
                                                <div class="col-6 mt-3">
                                                    <span class="option option-check">
                                                        <input type="radio" id="age-3" onChange={handleInputChange} name="age" value="10 - 50 años" />
                                                        <label for="age-3" class="mb-0 mr-0"><span class="square"></span>10 - 50 años</label>
                                                    </span>  
                                                </div>
                                                <div class="col-6 mt-3">
                                                    <span class="option option-check">
                                                        <input type="radio" id="age-4" onChange={handleInputChange} name="age" value="+50 años" />
                                                        <label for="age-4" class="mb-0 mr-0"><span class="square"></span>+50 años</label>
                                                    </span>  
                                                </div>

                                            </div>	
                                        </div>

                                        <div class="col-lg-6 camp d-none d-lg-block">
                                        <label>Garage</label>
                                            <div class="ticks d-flex justify-content-start align-items-center">
                                                <span class="option option-check mr-5">
                                                    <input type="radio" id="garage-1" onChange={handleInputChange} name="garage" value="Sí" />
                                                    <label for="garage-1" class="mb-0"><span class="square"></span>Sí</label>
                                                </span>  
                                                <span class="option option-check mr-5">
                                                    <input type="radio" id="garage-2" onChange={handleInputChange} name="garage" value="No" />
                                                    <label for="garage-2" class="mb-0"><span class="square"></span>No</label>
                                                </span>  
                                            </div>	
                                        </div>

                                        <div class="col-12 camp mt-4 mt-lg-0">
                                        <label class="mb-4">Amenities</label>
                                            <div class="mt-2 ticks row align-items-start justify-content-between justify-content-lg-start">
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Pileta" name="amenities" onClick={handleInputChange}  value="Pileta" />
                                                        <label for="Pileta"><span class="square"></span>Pileta</label>
                                                    </span>
                                                </div> 
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Apto crédito" name="amenities" onClick={handleInputChange}  value="Apto crédito" />
                                                        <label for="Apto crédito"><span class="square"></span>Apto crédito</label>
                                                    </span>
                                                </div> 
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Parrilla" name="amenities" onClick={handleInputChange}  value="Parrilla" />
                                                        <label for="Parrilla"><span class="square"></span>Parrilla</label>
                                                    </span>
                                                </div> 
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Solarium" name="amenities" onClick={handleInputChange}  value="Solarium" />
                                                        <label for="Solarium"><span class="square"></span>Solarium</label>
                                                    </span>
                                                </div> 
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Apto profesional" name="amenities" onClick={handleInputChange}  value="Apto profesional" />
                                                        <label for="Apto profesional"><span class="square"></span>Apto profesional</label>
                                                    </span>
                                                </div> 
                                                <div class="col-lg-3 col-6">
                                                    <span class="option option-check">
                                                        <input type="checkbox" id="Gimnasio" name="amenities" onClick={handleInputChange} value="Gimnasio" />
                                                        <label for="Gimnasio"><span class="square"></span>Gimnasio</label>
                                                    </span>
                                                </div> 
                                                </div>	
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="border-bottom-name my-lg-5 mt-lg-4"></div>
                                        </div>

                                        <div className="col-lg-12 d-flex justify-content-lg-end mb-3">
                                        <p style={{'font-size' : 1.1 + 'rem','font-weight': '400'}}>*Campos obligatorios.</p>
                                        </div>

                                        <div class="col-lg-5 camp">
                                            <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
                                            <button id="btn-state" className="btn btn-effect transparent d-inline-block" disabled={disabled}>ENVIAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :''
                    }
                </div>
            </div>
        </section>
    )
}

export default Main