import React from 'react'
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';

function About (){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    return  (
        <div className="about">
            <div className="container-fluid">
                <div className="col-12 d-lg-flex d-none justify-content-end">
                    <div className="content-logo">	
                        <img className="logo-absolute" src="assets/images/flor.svg" alt="" />
                        <img className="logo-absolute rotating" src="assets/images/about-icon.svg" alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <h2>NOSOTROS</h2>
                        <span>En 1983 Inmobiliaria Fanelli Trabalón nació y creció <br class="d-none d-lg-block" /> para brindarle soluciones con agilidad y creatividad comercial.</span>
                        {/* <span>INMOBILIARIA BOUTIQUE</span>
                        <span className="d-flex align-items-center justify-content-start w-100">20 <div className="line d-block mx-1"> </div>21</span> */}
                    </div>
                    <div className="col-lg-7 offset-lg-1">
                        <p>
                        En una Argentina en crecimiento, una familia de emprendedores le dió <br className="d-lg-block" />
                        su apellido al proyecto que tomaría el liderazgo en el rubro  <br className="d-lg-block" />
                        inmobiliario. Con nombre propio, que en adelante se convertiría <br className="d-lg-block" />
                        en sinónimo de bienes raíces, sus fundadores soñaron con que su <br className="d-lg-block" />
                        compañía fuera también un ejemplo de excelencia y tradición. <br className="d-lg-block" />
                            {/* La sociedad surge con el deseo de emprender en la ciudad <br className="d-lg-block" />
                            un nuevo concepto inmobiliario, en base a la experiencia <br className="d-lg-block" />
                            vivida de ambos fundadores en el mercado. <br className="d-lg-block" />
                            <br />
                            Priorizando la atención personalizada y con el uso de nuevas<br className="d-lg-block" />
                            tecnologías, buscamos que nuestros clientes tengan la mejor<br className="d-lg-block" />
                            experiencia al momento de invertir en un inmueble, simplificando<br className="d-lg-block" />
                            al máximo la toma de decisiones.<br className="d-lg-block" />
                            <br />
                            Es por eso que al momento de realizar una inversión<br className="d-lg-block" />
                            somos su mejor opción. */}
                        </p>
                        <Link to={settings.base_url + "nosotros"}  onClick={() => dispatch(changeLocationAction("nosotros"))} className="btn btn-effect transparent d-inline-block">QUIERO CONOCER MÁS</Link>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default About
