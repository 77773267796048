import React , {useEffect} from 'react'
import { useSelector } from 'react-redux';
import BannerTop from "../components/Modules/MediaPush/BannerTop";


// -- Componentes -- //

import Main from '../components/nosotros/main'

function Nosotros(){

    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = settings.seo.title
      }, []);
    return(
        <div className="nosotros">
            <BannerTop page="nosotros"/>
            <Main />
        </div>
    )
}

export default Nosotros
