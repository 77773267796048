import React , {useEffect} from 'react'
import {getNoveltiesAction,getFiltersNoveltiesAction,getTagsAction} from '../redux/noveltiesDucks'


// -- Componentes -- //
import Main from '../components/novedades/main'
import Blogs from '../components/novedades/blogs'
import Contact from '../components/form'
import { useDispatch, useSelector } from 'react-redux'


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Novedades(){

  // const data = useSelector((store) => store.novelties.novelties);
  // const dispatch = useDispatch()

  // console.log(data)

  // useEffect(() =>{
  //   data.lenght === 0 &&(
  //     dispatch(getNoveltiesAction()),
  //     dispatch(getFiltersNoveltiesAction())
  //   )
  // },[])

    ScrollToTopOnMount()
    return(
        <div className="novedades">
          <div className="layout">
            <Main />
            <div className="content">
                <Blogs />
                {/* <Contact /> */}
            </div>
          </div>
        </div>
    )
}

export default Novedades
