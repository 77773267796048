import {useState} from 'react'
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux'



const Main = () => {
    const [active,setActive] = useState(0)
    const {data} = useSelector(store => store.developments.developments)
    const settings = useSelector((store) => store.settings);


    function shortLocation (full_location){
        var split = full_location.split("|")
        console.log(split);
        return split[3] + '| ' + split[1] 
    }

    const getFeatured = (data) =>{
        return data.filter(element => element.is_starred_on_web)
    } 


    return data && getFeatured(data.objects) ?(
        <section className="main">
            <div className="content-image">
                {getFeatured(data.objects).map((item,index) =>(
                    <img className={'animate__animated animate__fadeIn animate__fast ' + (index == active ? 'd-block' : 'd-none')} src={(item.photos).find(element => element.is_front_cover === true).original} alt="" />
                ))}
            </div>
            <div className="container-fluid full-container">
                {
                    (getFeatured(data.objects).length) > 0 ?
                        <div className="content-slider d-flex justify-content-start align-items-center">
                            <div className="item active d-flex flex-column align-items-start animate__animated animate__fadeIn animate__fast">
                                <h3>{getFeatured(data.objects)[active].address}</h3>
                                <h5 className="location">{shortLocation(data.objects[active].location.full_location)}</h5>
                                <h5 className="surface">{getFeatured(data.objects)[active].surface}</h5>
                                <Link className="mt-auto" to={(settings.base_url+"emprendimientos/") + (getFeatured(data.objects)[active].id)}>VER MÁS</Link>
                                <div className="arrows">
                                    {active < getFeatured(data.objects).length -1  ? (<div onClick={() => setActive(active + 1)} className="next d-flex align-items-center justify-content-center"><i className="icon-arrow-right"></i></div>) : '' }
                                    {active > 0 ? <div onClick={() => setActive(active - 1)} className="back d-flex align-items-center justify-content-center"><i className="icon-arrow-right"></i></div> : ''}
                                </div>
                            </div>
                            {getFeatured(data.objects).map((item,index)=> (
                                active < index ? 
                                <div key={index} className="item d-none d-lg-block">
                                    <h3><span>{item.address}</span></h3>
                                </div>
                                :''
                            ))}
                        </div>
                    :
                        ''
                }
            </div>
        </section>
    ) 
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main