import BreadCrumbs from '../breadcrumbs'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import Map from '../map'


const Data = () =>{

    const {data} = useSelector(store => store.developments.development)    
    const settings = useSelector(store => store.settings)
    const unity_development = useSelector(store => store.developments.units_development.data)

    const [hidden,setHidden] = useState(true)

    function shortLocation (full_location){
        var split = full_location.split("|")
        return split[3] + '| ' + split[1] 
    }

    function addPoints(value){
        for (let index = 0; index < value.length; index++) {
            const element = array[index];
            
        }
    }

    function getPricesUnityPrice(){
        var valueUnity = []
        for (const iterator of unity_development.objects) {
            valueUnity.push(iterator.operations[0].prices[0].price)
        }
        return [(Math.min.apply(null, valueUnity)),(Math.max.apply(null, valueUnity))]
    }
    return data && unity_development ?  (
        <section className="data">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-12">
                    <BreadCrumbs props={[
                        {name:'Home',route:settings.base_url,location:''},
                        {name:'Emprendimientos',route:settings.base_url + "emprendimientos",location:'emprendimientos'},
                        {name:data.fake_address,route:'',location:'',active:true}
                    ]}/>
                    </div>
                    <div className="col-12">
                        <span className="type">{data.type.name}</span>
                    </div>
                    <div className="col-lg-6">
                        <h3 className="address">
                            {data.fake_address} <br />
                            <div className="location"> {shortLocation(data.location.full_location)}</div>
                        </h3>
                    </div>
                    <div className="col-lg-5 d-flex align-items-start justify-content-end">
                        <div className="d-flex align-items-center overflow-hidden">
                            <div className={'d-flex align-items-center ms-3 social-block ' + (hidden ? 'hidden' : '')}>
                                <a href={'mailto:' + '?subject='  + data.fake_address + '&body=' + window.location} className="btn p-0 d-flex align-items-center justify-content-center"><i className="icon-ico-sendmail"></i></a>
                                <a href={"https://api.whatsapp.com/send?text=Hola, quería compartirte este loteo de " +  settings.name  + " " + window.location} target="_blank" className="btn p-0 d-flex align-items-center justify-content-center"><i className="icon-whatsapp"></i></a>
                            </div>
                            <div onClick={() => setHidden(!hidden)} className="btn p-0 d-flex align-items-center justify-content-center me-2 icon-share"></div>
                        </div>
                        {/* <div className="btn p-0 d-flex align-items-center justify-content-center icon-like"></div> */}
                    </div>
                    <div className="col-lg-6">
                        <p>
                            {data.description}

                        </p>
                    </div>
                    <div className="col-lg-5">
                        <div className="row">
                            {data.tags.map((item) => (
                                <div className="col-6 mb-3">
                                    <div className="item d-flex">
                                        <div className="tick d-flex justify-content-center align-items-center me-3">
                                            <i className="icon-tick"></i>
                                        </div>
                                        {item.name}
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
                <div className="line"></div>
                { unity_development.objects.length > 0 ?
                <>
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 d-flex justify-content-between">
                            <div className="both">
                                <h5>TIPO</h5>
                                <span>{data.type.name}</span>
                            </div>
                                <div className="both">
                                    <h5>DESDE</h5>
                                    <span>{unity_development.objects[0].operations[0].prices[0].currency} {new Intl.NumberFormat("de-DE").format(getPricesUnityPrice()[0])}</span>
                                </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 d-flex justify-content-between">
                            <div className="both">
                                <h5>UNIDADES</h5>
                                <span>{unity_development.meta.total_count}</span>
                            </div>
                                <div className="both">
                                <h5>HASTA</h5>
                                <span>{unity_development.objects[0].operations[0].prices[0].currency} {new Intl.NumberFormat("de-DE").format(getPricesUnityPrice()[1])}</span>
                            </div>

                        </div>
                    </div>
                </>
                :
                ''
                }
            </div>
            <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />


        </section>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Data