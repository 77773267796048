import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearNoveltyAction, getNoveltieAction, getNoveltiesAction } from '../../redux/noveltiesDucks'
import { getTextShort,getDateTime, getCompleteName } from '../../functions/functions_novelties'

import { Link } from 'react-router-dom'

function featuredNews (){
    const dispatch = useDispatch()
    const data = useSelector((store) => store.novelties.novelties);
    const settings = useSelector((store) => store.settings);

    // useEffect(() => {
    //     dispatch(getNoveltiesAction())
    // }, [])

    const getThree = (data) => {
        // Agarro los primeros 3 activos por position
        return data.slice(0,3)
    }

    console.log(data)

    return data.length > 0 && (
        <section class="novelties-home py-5" >
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <p class="section-name">NOVEDADES</p>
                    </div>
                    <div class="col-12 col-lg-10">
                        <h2 className='mt-3 mt-lg-0'>MANTENETE AL TANTO DE LAS ÚLTIMAS TENDENCIAS.</h2>
                    </div>
                    
                    <div class="col-12 col-lg-10 offset-0 offset-lg-2 pt-5">
                        <div class="row py-lg-5">   
                            {getThree(data.sort((a,b)=>b.position-a.position)).map((item,index) => (
                                <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                    <article class="novelty-card mt-4">
                                        <div style={{backgroundImage: "url(" + item.image + ")"}} className="img-novelty"></div>
                                        <div className="div-content d-flex flex-column p-4 text-start">
                                            <div className="div_texto">
                                                <span className="date">{getDateTime(item.date_published)}</span>
                                                <h2 className="title">
                                                    {/* {item.title} */}
                                                    {getTextShort(item.title,80)}
                                                </h2>
                                                <p className="description mt-5">
                                                {getTextShort(item.headline,300)}
                                                </p>
                                            </div>
                                            <div className="div_button col-12 text-center mt-auto d-lg-flex justify-content-center">
                                                <Link onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(item.id))} to={"/novedades/" + item.id + "/" + item.slug} class=" btn btn-blue mt-3 mt-lg-0 ">Ver Más <span className="span-more"><span className="first-i">+</span></span></Link>
                                            </div>
                                        </div>
                                        <span className="by-name">
                                            por {getCompleteName(item)}
                                        </span>
                                    </article>  
                                </div>  
                            ))}
                            
                        </div>
                    </div>
                    <div class="col-12 col-lg-10 mt-5 offset-lg-2 text-center text-lg-start">
                        <Link to="novedades" class="btn btn-all">Ver todas las novedades</Link>
                    </div>
                </div>
            </div>
        </section>
       
    )
}

export default featuredNews
