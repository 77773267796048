import SimpleReactLightbox ,{SRLWrapper} from 'simple-react-lightbox'
import {useSelector} from 'react-redux'


const Main = () =>{
    const options = {
        settings: {
        	autoplaySpeed: 1500,
        	transitionSpeed: 900
        },
        buttons: {
        	iconColor: '#ffff'
        },
        caption: {
        	captionColor: '#fff',
        	captionFontFamily: 'Raleway, sans-serif',
        	captionFontWeight: '300',
        	captionTextTransform: 'uppercase'
        },
        progressBar: {
        	height: '20px',
        	fillColor: '#191919',
        	backgroundColor: 'white'
        }
    };
    const {data} = useSelector(store => store.developments.development)

    

    return data ? (
        <section className="main" style={{'backgroundImage' : 'url(' + (data.photos).find(element => element.is_front_cover === true).original + ')'}}>
            <div className="container-fluid">
                <div className="content-main d-flex justify-content-between align-items-lg-end align-items-center text-start text-lg-start pb-lg-5 w-100">
                    <h2>
                        {data.publication_title} <br />
                    </h2>
                        <SimpleReactLightbox>
                            <SRLWrapper options={options}>
                                <a className="btn d-flex justify-content-center align-items-center position-relative overflow-hidden"><i className="icon-picture"></i><img  src={data.photos[1].original} className="position-absolute opacity-none" style={{width:'100%', height:'100%', opacity:'0'}} alt="" /></a>
                                    {/* <a className="content-img" href="../assets/images/main-lotes.jpg" caption=""> */}
                                        {data.photos.map((item,index) => (
                                            <div>
                                                {(index !== 1 &&  !item.is_front_cover)  ? 
                                                    <img className="img-bluepints d-none" src={item.original}/>
                                                : 
                                                    ''
                                                }
                                            </div>
                                        ))}
                                    {/* </a> */}
                            </SRLWrapper>
                        </SimpleReactLightbox>
                </div>
            </div>
        </section>
    ): (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main