import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Investors = () => {
    const settings = useSelector((store) => store.settings);    
    return (
        <section className="investors">
            <div className="container-fluid">
                <h4>Inversores</h4>
                <div className="row justify-content-center text-center">
                    <div className="col-lg-4">
                        <div className="item">
                            <img src="assets/images/noun_investment.svg" alt="" />
                            <h5 className="pt-5">Grandes Inversores</h5>
                            <p>
                                Inversores que participen aportando 
                                capital para la compra de la tierra
                                o para desarrollo de proyectos,
                                para maximizar su rentabilidad.
                            </p>
                            <Link to={settings.base_url + 'contacto'} className="btn">CONTACTANOS</Link>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="item">
                            <img src="assets/images/noun_leadership.svg" alt="" />
                            <h5 className="pt-5">Usuario Finales <br /> e Inversores Minoristas</h5>
                            <p>
                                Empresas o particulares que cuenten  
                                con capital para sumarse  
                                a algunos de los proyectos liderados  
                                por la desarrolladora, ya sea como  
                                inversión como para uso propio. 
                            </p>
                            <Link to={settings.base_url + 'contacto'} className="btn">CONTACTANOS</Link>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="item">
                            <img src="assets/images/noun_land.svg" alt="" />
                            <h5 className="pt-5">Dueños de Tierra</h5>
                            <p>
                                Inversores que participen aportando 
                                capital para la compra de la tierra
                                o para desarrollo de proyectos,
                                para maximizar su rentabilidad
                            </p>
                            <Link to={settings.base_url + 'contacto'} className="btn">CONTACTANOS</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
}

export default Investors