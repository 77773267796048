import React, { useEffect, useRef } from "react"
import {useLocation} from "react-router-dom";
import Countdown from "react-countdown"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import Star from "../../../assets/images/star-popup.svg"
import Square from "../../../assets/images/cuadro.svg"
import { convertStringToTime } from '../../../functions/functions_actions';
import { informCustomEvent } from "../../../helpers/helper.analytics"

const CounterPopup = ({linkTo, linkTxt, title, text, showOn, band, eventDate, utmValue,small_type,small_section,name}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')

    // Timeout to show the component
    useEffect(() => {
        if(checkPage()) {
            setTimeout(() => {
                pop?.current?.open()
            }, 1000)
        }
    }, [pathname])

    // Check where shows
    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().includes('emprendimientos') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento'){return true}
        if(pathname.toLowerCase().includes('propiedad') && showOn.toLowerCase() === 'ficha de la propiedad'){return true}
        if(pathname.toLowerCase() === 'ventas' && showOn.toLowerCase() === 'resultados venta'){return true}
        if(pathname.toLowerCase() === 'alquileres' && showOn.toLowerCase() === 'resultados alquiler'){return true}
        if(pathname.toLowerCase() === 'alquileres-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){return true}
        if(pathname.toLowerCase() === 'emprendimientos' && showOn.toLowerCase() === 'emprendimientos'){console.log("true"); return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }


    // Render countdown
    const rendererCtd = ({ days, hours, minutes, seconds }) =>
        <div className="counter d-flex justify-content-center text-center mt-4">
            <div className="mx-2 d-flex flex-column justify-content-center">
                <span className="date-val">{days}</span> 
                <span className="date-text">DÍAS</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{hours}</span> 
                <span className="date-text">HS</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{minutes}</span> 
                <span className="date-text">MIN</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{seconds}</span> 
                <span className="date-text">SEG</span>
            </div>
        </div>

    const pop = useRef()

    return checkPage() ? (
        <Popup ref={pop} position="right center">
            <i className="icon-delete close" type="button" role="button" id="closePopup"data-bs-dismiss="modal" aria-label="Close" onClick={() => pop.current.close()}></i>
            {/* <button className="close" > */}
                {/* &times; */}
            {/* </button> */}
            <div className={`content-border ${band ? 'show-band' : 'hide-band'}`}>
                {/* <Square className={`square-content ${band ? 'show' : 'hide'}`}/> */}
                { band && 
                    <div className="band">
                        <span className="text-band text-center">{band}</span>
                    </div> }
                {/* <Star className="star-icon"/> */}
                <div className="star-icon">
                    <i className="icon-star"></i>
                </div>
                <h1 className="text-center text-uppercase">{title}</h1>
                {text && <p className="popup-text my-3 text-center">{text}</p>}
                <Countdown  
                    date={new Date(eventDate).getTime()} 
                    renderer={rendererCtd}/> 
                {linkTo && <a className="d-flex text-center align-items-center justify-content-center text-uppercase" 
                    onClick={() => pop.current.close() + informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name) } href={linkTo} target="_blank">{linkTxt}</a>}
            </div>
        </Popup>
    ) : null
}

export default CounterPopup

// Component usage
// <CounterPopup 
//      linkTo={'/servicios'} -> url link
//      linkTxt={'A un click de conocerlo'} -> text link
//      title={'¡Nuevo emprendimiento!'} -> text title
//      showOn={'nosotros'} -> where show | add to components page
//      band={'Proyecto de pozo, texto de prueba'} -> band top right
//      eventDate={'9/15/2022'}/> -> event date
